import "../styles/global.scss";
import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Seo from "../components/SEO";

import Header from "../components/Header";
import Footer from "../components/Footer";
import Hero from "../components/Hero";
import ScrollingBar from "../components/ScrollingBar";
import WhatIs from "../components/WhatIs";
import Play from "../components/Play";
import Focus from "../components/Focus";
import Tokenomics from "../components/Tokenomics";
import Roadmap from "../components/Roadmap";
import Team from "../components/Team.js";
import bg from "../images/bg2.png";

// markup
const IndexPage = () => {
  let image = useRef(null);
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    gsap.from(image, {
      scale: 1.3,
      ease: "Power3.easeOut",
      scrollTrigger: {
        trigger: image,
        scrub: 1,
      },
    });
  }, []);

  return (
    <>
      <Seo />
      <Header />
      <Hero />
      <ScrollingBar />
      <WhatIs />
      <Play />
      <Focus />
      <Tokenomics />
      <section className="see">
        <img src={bg} alt="" ref={(el) => (image = el)} className="img-abs" />
        <Roadmap />
        <Team />
        <Footer />
      </section>
    </>
  );
};

export default IndexPage;
