import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import bg from "../images/cube.png";

export default function Play() {
  let play = useRef(null);
  let infow = useRef(null);
  let infow2 = useRef(null);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    gsap.to(play.children[0], {
      scale: 1.1,
      rotation: 2,
      ease: "Power3.easeOut",
      scrollTrigger: {
        scrub: 1,
      },
    });

    gsap.from([infow.children, infow2], {
      opacity: 0,
      y: 50,
      duration: 2,
      stagger: 0.05,
      ease: "Power3.easeOut",
      scrollTrigger: {
        trigger: infow,
      },
    });
  }, []);

  return (
    <section ref={(el) => (play = el)} className="play">
      <img src={bg} alt="" className="img-abs" />
      <div ref={(el) => (infow = el)} className="play__info">
        <h2 className="title">Play the game</h2>
        <p>
          Based on the script, the main mission is to help Mi’ka give
          decentralisation back to the people. For this, she has to find The
          Vaults that have been hidden across the Metaverse to recover a series
          of CryptoCubes, unlocking the secrets of cryptocurrency.
        </p>
        <p>
          The game will play out in the form of chapters, during which users
          will have to watch detailed cutscenes and look for clues, eventually
          using them to complete missions and progress to the next stage.
        </p>
        <p>
          Decoding mysteries and completing quests will grant Mi’ka access to
          the hidden vaults, and subsequently grant her with CryptoCubes,
          progressing to the next chapter or stage.
        </p>
        <p>
          Through the gameplay, users aim to collect legendary NFTs by
          completing levels, but also by purchasing these with the $MISMA token
          to get special advantages in order to proceed further.
        </p>
        <p>
          Users will also be granted various assets as they progress, including
          props and movie footage. In the final chapter, the CryptoCubes will
          merge into a rare NFT - the Quantum Cube.
        </p>
      </div>
      <div ref={(el) => (infow2 = el)} className="play__center">
        <p>
          The minimum requirement to start the game will be the purchase of the
          genesis NFT - The Legacy Cube with $MISMA tokens.
        </p>
      </div>
    </section>
  );
}
