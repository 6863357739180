import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import bg from "../images/focus.png";

export default function Focus() {
  let focus = useRef(null);
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    gsap.from(focus.children, {
      opacity: 0,
      y: 50,
      duration: 2,
      stagger: 0.15,
      ease: "Power3.easeOut",
      scrollTrigger: {
        trigger: focus,
      },
    });
  }, []);
  return (
    <section className="focus">
      <img src={bg} alt="" className="img-abs" />
      <div ref={(el) => (focus = el)} className="focus__grid">
        <div className="focus__col">
          <p>
            Watch and play as an immersive storyline unfolds and get rewarded
            for your progress
          </p>
        </div>
        <div className="focus__col">
          <p>
            Exciting, high-quality, super-realistic AAA graphics built with
            Unreal Engine 5 and MetaHuman
          </p>
        </div>
        <div className="focus__col">
          <p>
            Blockchain-based framework, hosted on Binance Smart Chain,
            leveraging the feature of Non-Fungibility that ERC-721 offers.
          </p>
        </div>
      </div>
    </section>
  );
}
