import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default function Roadmap() {
  let roadmap = useRef(null);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    gsap.from(roadmap.children, {
      opacity: 0,
      y: 80,
      duration: 2,
      stagger: 0.05,
      ease: "Power3.easeOut",
      scrollTrigger: {
        trigger: roadmap,
      },
    });
  }, []);

  return (
    <section className="roadmap" id="roadmap">
      <div className="container">
        <h2 className="title">ROADMAP</h2>
        <ul ref={(el) => (roadmap = el)}>
          <li>
            <span>Q4 2021</span>
            <p>
              Concept development
              <br />
              Synopsis
              <br /> Core Branding
            </p>
          </li>
          <li>
            <span>Q1 2022</span>
            <p>
              Strategic partnerships
              <br />
              Whitepaper
              <br />
              $MISMA token development
              <br />
              Website
              <br />
              NFT & character drafts
              <br />
              Script writing
              <br />
              Marketplace demo
              <br />
              Seed & Pre-sale round
            </p>
          </li>
          <li>
            <span>Q2 2022</span>
            <p>
              Animation and game development <br />
              Release of first chapters <br />
              Marketplace launch <br />
              Public sale round
            </p>
          </li>
          <li>
            <span>Q3 2022</span>
            <p>Release of all chapters</p>
          </li>
          <li>
            <span>Q4 2022</span>
            <p>
              Partnerships with streaming platforms
              <br />
              Start of development for Nu’Misma Vol. 2
            </p>
          </li>
        </ul>
      </div>
    </section>
  );
}
