import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import bg from "../images/bg.png";

export default function Tokenomics() {
  let info = useRef(null);
  let seed = useRef(null);
  let allocation = useRef(null);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    gsap.from(info.children, {
      opacity: 0,
      y: 50,
      duration: 2,
      stagger: 0.05,
      ease: "Power3.easeOut",
      scrollTrigger: {
        trigger: info,
      },
    });

    gsap.from(seed.children, {
      opacity: 0,
      y: 50,
      duration: 2,
      stagger: 0.05,
      ease: "Power3.easeOut",
      scrollTrigger: {
        trigger: seed,
      },
    });

    gsap.from(allocation, {
      opacity: 0,
      y: 50,
      duration: 2,
      stagger: 0.05,
      ease: "Power3.easeOut",
      scrollTrigger: {
        trigger: allocation,
      },
    });
  }, []);

  return (
    <section className="tokenomics" id="tokenomics">
      <img src={bg} alt="" className="img-abs" />
      <div className="container">
        <div className="tokenomics__info" ref={(el) => (info = el)}>
          <h2 className="title">Tokenomics</h2>
          <p>
            $MISMA is Nu’Misma’s own currency, built on Binance Smart Chain and
            providing utility for all the in-and-out-game purchases for the
            Numisma NFTs. The total supply of the MISMA token is 500,000,000,
            which will be distributed as follows:
          </p>
        </div>
        <div className="diagram" ref={(el) => (seed = el)}>
          <div className="lines">
            <span></span>
            <span></span>
            <span></span>
          </div>
          <div className="diagram__grid">
            <div className="diagram__col">
              <div className="diagram__pos diagram__pos--1">
                <span>50 mIL</span>
              </div>
              <h3>SEED</h3>
              <div className="diagram__box">
                <div>
                  <span>Price</span>
                  <strong>0,05 USD</strong>
                </div>
                <div>
                  <span>Sale</span>
                  <strong>10%</strong>
                </div>
              </div>
            </div>
            <div className="diagram__col ">
              <div className="diagram__pos diagram__pos--2">
                <span>50 mIL</span>
              </div>
              <h3>PRIVATE</h3>
              <div className="diagram__box diagram__box--2">
                <div>
                  <span>Price</span>
                  <strong>0,075 USD</strong>
                </div>
                <div>
                  <span>Sale</span>
                  <strong>7%</strong>
                </div>
              </div>
            </div>
            <div className="diagram__col">
              <div className="diagram__pos diagram__pos--3">
                <span>50 mIL</span>
              </div>
              <h3>PUBLIC</h3>
              <div className="diagram__box diagram__box--3">
                <div>
                  <span>Price</span>
                  <strong>0,10 USD</strong>
                </div>
                <div>
                  <span>Sale</span>
                  <strong>5%</strong>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div ref={(el) => (allocation = el)} className="allocation">
          <table>
            <tr>
              <th>Category</th>
              <th>
                Allocation <br />%
              </th>
              <th>
                Allocation <br />
                $MISMA
              </th>
            </tr>
            <tr>
              <td>RESERVE</td>
              <td>10%</td>
              <td>50 000 000</td>
            </tr>
            <tr>
              <td>
                Community
                <br /> Rewards
              </td>
              <td>13%</td>
              <td>65 000 000</td>
            </tr>
            <tr>
              <td>
                Project
                <br /> Development
              </td>
              <td>20%</td>
              <td>100 000 000</td>
            </tr>
            <tr>
              <td>Marketing</td>
              <td>10%</td>
              <td>50 000 000</td>
            </tr>
            <tr>
              <td>
                Advisory
                <br /> Board
              </td>
              <td>5%</td>
              <td>25 000 000</td>
            </tr>
            <tr>
              <td>Team</td>
              <td>20%</td>
              <td>100 000 000</td>
            </tr>
            <tr>
              <td>Total Supply</td>
              <td colSpan="2">500 000 000</td>
            </tr>
          </table>
        </div>
      </div>
    </section>
  );
}
