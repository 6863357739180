import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import girl from "../images/girl.jpg";

import whitepaper from "../images/NuMisma_Whitepaper.pdf";
import story from "../images/Numisma_the_story.pdf";

export default function WhatIs() {
  let what = useRef(null);
  let info = useRef(null);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    gsap.to(what.children[0], {
      scale: 1.1,
      rotation: 2,
      ease: "Power3.easeOut",
      scrollTrigger: {
        scrub: 1,
      },
    });

    gsap.from(info.children, {
      opacity: 0,
      y: 50,
      duration: 2,
      stagger: 0.05,
      ease: "Power3.easeOut",
      scrollTrigger: {
        trigger: info,
      },
    });
  }, []);

  return (
    <section ref={(el) => (what = el)} className="what" id="experience">
      <img src={girl} alt="" className="img-abs" />
      <div className="what__info" ref={(el) => (info = el)}>
        <h2 className="title">What is Nu’Misma</h2>
        <p>
          Nu’misma is an innovative hybrid project based on a gamified animated
          movie and combining it for the first time with the emerging technology
          of NFTs, through an immersive concept - WATCH & PLAY.
        </p>
        <p>
          Through a compelling storyline based on the fictional future of
          blockchain, the world of Nu’misma will open a meaningful journey for
          its audience through animation, rewarding them for their progress
          through each episode.
        </p>
        <p>
          Numisma is the first immersive animation movie on the blockchain,
          using gaming elements and NFT technology, being the 1st part of a
          series of 100, split in 7 exciting chapters.
        </p>
        <div className="what__links">
          <a
            className="link "
            href={whitepaper}
            target="_blank"
            rel="noreferrer"
          >
            Whitepaper
          </a>
          <a className="link" href={story} target="_blank" rel="noreferrer">
            The Story
          </a>
        </div>
      </div>
    </section>
  );
}
