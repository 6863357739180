import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default function Team() {
  let team = useRef(null);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    gsap.from(team.children, {
      opacity: 0,
      y: 80,
      duration: 2,
      stagger: 0.05,
      ease: "Power3.easeOut",
      scrollTrigger: {
        trigger: team,
      },
    });
  }, []);

  return (
    <section className="team">
      <div className="container">
        <h2 className="title">Team</h2>
        <ul ref={(el) => (team = el)}>
          <li>
            <h3>Mylan L.</h3>
            <span>Founder</span>
          </li>
          <li>
            <h3>Alex R.</h3>
            <span>Chief Technical Officer</span>
          </li>
          <li>
            <h3>Ana R.</h3>
            <span>Chief Creative Officer</span>
          </li>
          <li>
            <h3>Robert M</h3>
            <span>Senior 3D Artist</span>
          </li>
          <li>
            <h3>Andra S.</h3>
            <span>Marketing</span>
          </li>
          <li>
            <h3>Belkacem Z.</h3>
            <span>Concept Artist</span>
          </li>
          <li>
            <h3>Macauley H.</h3>
            <span>Copywriter</span>
          </li>
          <li>
            <h3>Kian H.</h3>
            <span>3D Artist</span>
          </li>
          <li>
            <h3>Minor W.</h3>
            <span>Designer</span>
          </li>
          <li>
            <h3>Lee Z.</h3>
            <span>Game Developer</span>
          </li>
          <li>
            <h3>Oliver T.</h3>
            <span>Motion Designer</span>
          </li>
        </ul>
      </div>
    </section>
  );
}
