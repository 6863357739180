import React from "react";

export default function ScrollingBar() {
  return (
    <>
      <div className="scrollingbar">
        <div className="line">
          <span>
            THE FIRST WATCH AND PLAY SERIES ON THE BLOCKCHAIN. DISCOVER AND OWN
            THE EPISODES AS NFTS!
          </span>
          <span>
            THE FIRST WATCH AND PLAY SERIES ON THE BLOCKCHAIN. DISCOVER AND OWN
            THE EPISODES AS NFTS!
          </span>
          <span>
            THE FIRST WATCH AND PLAY SERIES ON THE BLOCKCHAIN. DISCOVER AND OWN
            THE EPISODES AS NFTS!
          </span>
          <span>
            THE FIRST WATCH AND PLAY SERIES ON THE BLOCKCHAIN. DISCOVER AND OWN
            THE EPISODES AS NFTS!
          </span>
        </div>
      </div>
    </>
  );
}
