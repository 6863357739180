import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import bg from "../images/hero.png";
import { Link } from "gatsby";

import instagram from "../images/instagram.svg";
import twitter from "../images/twitter.svg";
import telegram from "../images/telegram.svg";

export default function Hero() {
  let hero = useRef(null);
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const image = hero.children[0];
    const info = hero.children[1].children[0].children;
    const raised = hero.children[3];
    const social = hero.children[2];

    gsap.to(image, {
      scale: 1.3,
      rotation: 2,
      ease: "Power3.easeOut",
      scrollTrigger: {
        scrub: 1,
      },
    });

    gsap.from(info, {
      opacity: 0,
      y: 50,
      duration: 2,
      stagger: 0.1,
      ease: "Power3.easeOut",
      scrollTrigger: {
        trigger: hero,
      },
    });
    gsap.from(social, {
      opacity: 0,
      x: 80,
      duration: 2,
      ease: "Power3.easeOut",
    });

    gsap.from(raised, {
      opacity: 0,
      y: 50,
      duration: 2,
      ease: "Power3.easeOut",
      scrollTrigger: {
        trigger: hero,
      },
    });
  }, []);

  return (
    <>
      <section ref={(el) => (hero = el)} className="hero">
        <img src={bg} alt="" className="img-abs" />
        <div className="container">
          <div className="hero__info">
            <h1 className="title">A masterpiece of animation and technology</h1>

            <div className="hero__cta">
              <Link to="#" className="btn">
                Join Pre-ICO
              </Link>
              <p>
                Blended together into an impressive hybrid concept - the first
                volume of gamified series developed on the blockchain, where the
                audience owns each episode as NFTs.
              </p>
            </div>
          </div>
        </div>
        <div className="hero__social">
          <a
            href="https://www.instagram.com/numismagenesis/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={instagram} alt="" />
          </a>
          <a
            href="https://twitter.com/numismagenesis"
            target="_blank"
            rel="noreferrer"
          >
            <img src={twitter} alt="" />
          </a>
          <a
            href="https://t.me/numismagenesis"
            target="_blank"
            rel="noreferrer"
          >
            <img src={telegram} alt="" />
          </a>
        </div>
        <div className="raised">
          <div className="raised__status">
            <div className="raised__total">
              <span>Total:</span>
              <span>500 000 000 MISMA</span>
            </div>
            <div className="status__bar">
              <div className="bar"></div>
              <div className="legend">
                <span>0 MISMA</span>
                <span>500.000.000 MISMA</span>
              </div>
            </div>
          </div>
          <ul className="raised__left">
            <li>
              <span>Current rate:</span>
              <strong>1 MISMA = 0.05 USD</strong>
            </li>
            <li>
              <span>USD raised:</span>
              <strong>USD</strong>
            </li>
          </ul>
        </div>
      </section>
    </>
  );
}
