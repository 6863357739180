import React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

const getData = graphql`
  query {
    site {
      siteMetadata {
        siteTitle: title
        siteDesc: description
        siteImage: image
        siteUrl
      }
    }
  }
`;

const SEO = ({ title, description, image }) => {
  const { site } = useStaticQuery(getData);
  const { siteDesc, siteTitle, siteUrl, siteImage } = site.siteMetadata;
  return (
    <Helmet
      htmlAttributes={{ lang: "en" }}
      title={title ? `${title} | ${siteTitle}` : siteTitle}
    >
      <meta name="description" content={description || siteDesc} />
      <meta name="image" content={image || siteImage} />

      {/* Facebook Card */}
      <meta property="og:url" content={siteUrl} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={siteTitle} />
      <meta property="og:description" content={description || siteDesc} />
      <meta property="og:image" content={`${siteUrl}/${image || siteImage}`} />
      <meta property="og:image" content="400" />
      <meta property="og:image:height" content="300" />

      {/* Twitter Card */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={siteTitle} />
      <meta name="twitter:description" content={description || siteDesc} />
      <meta name="twitter:image" content={`${siteUrl}/${image || siteImage}`} />
    </Helmet>
  );
};

export default SEO;
